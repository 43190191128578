<div class='main-nav'>
    <div class='navbar navbar-inverse'>
        <div class='navbar-header'>
            <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse'
                [attr.aria-expanded]='isExpanded' (click)='toggle()'>
                <span class='sr-only'>Toggle navigation</span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
            </button>
            <a class='navbar-brand' [routerLink]='["/"]'>xvrStocks</a>
        </div>
        <div class='clearfix'></div>
        <div class='navbar-collapse collapse' [ngClass]='{ "in": isExpanded }'>
            <ul class='nav navbar-nav'>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/cash"]' (click)='collapse()'>
                        <span class='glyphicon glyphicon-th-list'></span> Cash
                    </a>
                </li>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/stock"]' (click)='collapse()'>
                        <span class='glyphicon glyphicon-th-list'></span> Market
                    </a>
                </li>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/portfolio"]' (click)='collapse()'>
                        <span class='glyphicon glyphicon-th-list'></span> Portfolio
                    </a>
                </li>
                <li>
                    <a (click)='logout()'>
                        <span class='glyphicon glyphicon-th-list'></span> Logout
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>