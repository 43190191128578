import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { AuthGuard } from './auth.guard';
import { Routes,  RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CashComponent } from './cash/cash.component';
import { AssetComponent } from './asset/asset.component';
import { PortfolioComponent } from './portfolio/portfolio.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'cash', component: CashComponent, canActivate: [AuthGuard]},
    { path: 'stock', component: AssetComponent, canActivate: [AuthGuard] },
    { path: 'portfolio', component: PortfolioComponent , canActivate: [AuthGuard]},
    { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      routes
    )
  ]
  , providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
