<mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>
<mat-checkbox style="padding-left: 20px" [(ngModel)]="showClosedPositions" (change)="onToggleShowPositions()">
    Closed Positions</mat-checkbox>
<mat-checkbox style="padding-left: 20px" [(ngModel)]="showOpenPositions" (change)="onToggleShowPositions()">
    Open Positions</mat-checkbox>
<mat-table [dataSource]="assetList" matSort>
    <!-- Symbol -->
    <ng-container matColumnDef="symbol">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Symbol </mat-header-cell>
        <mat-cell class="asset-symbol" *matCellDef="let asset"><a (click)="onClickSymbol(asset.assetId)" [matTooltip]="asset.name"
                matTooltipPosition="right">{{asset.symbol}} </a> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cost">
        <mat-header-cell class="wide-only" *matHeaderCellDef mat-sort-header> Cost </mat-header-cell>
        <mat-cell class="wide-only" *matCellDef="let asset">{{asset.cost | number:'1.2-2'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="proceeds">
        <mat-header-cell class="wide-only" *matHeaderCellDef mat-sort-header> Proceeds </mat-header-cell>
        <mat-cell class="wide-only" *matCellDef="let asset">{{asset.proceeds | number:'1.2-2'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Value </mat-header-cell>
        <mat-cell *matCellDef="let asset">{{asset.value | number:'1.2-2'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="change">
        <mat-header-cell *matHeaderCellDef mat-sort-header> +/-% </mat-header-cell>
        <mat-cell *matCellDef="let asset">{{asset.change | number:'1.2-2'}}%</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>