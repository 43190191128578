import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { BackEndService } from './services/back-end.service';
import { AssetComponent } from './asset/asset.component';
import { AssetPositionInfoComponent } from './asset-position-info/asset-position-info.component';
import { AssetSelectorComponent } from './asset-selector/asset-selector.component';
// import { TransactionComponent } from './transaction/transaction.component';
import { CashComponent } from './cash/cash.component';
import { CashPositionInfoComponent } from './cash-position-info/cash-position-info.component';
import { AssetEventHistoryComponent } from './asset-event-history/asset-event-history.component';
import { BookingComponent } from './booking/booking.component';
import { EventComponent } from './event/event.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
// import { routing } from './app.routing';
import { AppRoutingModule } from './app-routing.module';
import { MatNativeDateModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    AssetComponent,
    AssetPositionInfoComponent,
    AssetSelectorComponent,
    // TransactionComponent,
    CashComponent,
    CashPositionInfoComponent,
    AssetEventHistoryComponent,
    BookingComponent,
    EventComponent,
    PortfolioComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule.forRoot([
    //   { path: '', component: HomeComponent, pathMatch: 'full' },
    //   // { path: 'counter', component: CounterComponent },
    //   // { path: 'fetch-data', component: FetchDataComponent },
    //   { path: 'cash', component: CashComponent },
    //   { path: 'stock', component: AssetComponent },
    //   { path: 'portfolio', component: PortfolioComponent },
    // ]),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    // MatMomentDateModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTooltipModule
  ],
  providers: [
    BackEndService,
    //  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
