import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BookingDMC } from './booking.dmc';
import { BookingTypeEnum } from '../dto/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
})
export class BookingComponent implements OnInit, OnDestroy {
  constructor() {

  }
  @Input() booking: BookingDMC;
  bookingTypeEnumeration = BookingTypeEnum;

  amount: number;
  price: number;
  quantity: number;

  subscribe_BookingDTOChange: Subscription;
  ngOnInit() {
    this.subscribe_BookingDTOChange = this.booking.subject_DTOChanges.asObservable()
      .subscribe(dto => {
        // this.amount = this.round(dto.amount, 2);
        this.amount = dto.amount;
        this.price = dto.price;
        this.quantity = dto.quantity;
      });
  }
  round(num: number, precision: number) {
    const multiplier = Math.pow(10, precision);
    return Math.round((num + 0.00001) * multiplier) / multiplier;
  }
  onKeyUp() {
    this.booking.changeValue(this.amount, this.price, this.quantity);
  }
  onClickMenu(field: string) {
    this.booking.show[field] = true;
  }
  onDblClick(target: string) {
    this.booking.changeCalculationTarget(target);
  }
  ngOnDestroy() {
    this.subscribe_BookingDTOChange.unsubscribe();
  }
}


