import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable, Subscription } from 'rxjs';
import { AssetIdentifierDTO } from '../dto/asset-identifier.dto';
// import { AssetInfoDTO } from '../dto/asset-info.dto';
// import { AssetEventsDTO } from '../dto/asset-events.dto';
import { EventDTO } from '../dto/event.dto';
import { AssetTotalsDTO } from '../dto/asset-totals.dto';
import { IQuote } from '../dto/i-quote';
import { AssetDTO } from '../dto/asset-dto';
import { AssetTypeEnum } from '../asset/asset.dmc';
import { OriginalSource } from 'webpack-sources';
import { CMNotifier } from '../utils/cm-notifiers';

export class BroadcastService {
    public eventSelected = new CMSubscriber<number>(AppEventEnum.eventSelected, false);
    public eventFetched = new CMSubscriber<EventDTO>(AppEventEnum.eventFetched, false);


    private subject_AssetIdentifierPosted = new BehaviorSubject<number>(null);
    get observe_AssetIdentifierPosted(): Observable<number> {
        return this.subject_AssetIdentifierPosted.asObservable();
    }
    private subject_AssetFocused = new BehaviorSubject<number>(null);
    get observe_AssetFocused(): Observable<number> {
        return this.subject_AssetFocused.asObservable();
    }
    private subject_AssetLoaded = new BehaviorSubject<AssetDTO>(null);
    get observe_AssetLoaded(): Observable<AssetDTO> {
        return this.subject_AssetLoaded.asObservable();
    }
    private subject_AssetTotalsLoaded = new BehaviorSubject<AssetDTO>(null);
    get observe_AssetTotalsLoaded(): Observable<AssetDTO> {
        return this.subject_AssetTotalsLoaded.asObservable();
    }
    private subject_AllAssetTotalsLoaded = new BehaviorSubject<AssetDTO[]>(null);
    get observe_AllAssetTotalsLoaded(): Observable<AssetDTO[]> {
        return this.subject_AllAssetTotalsLoaded.asObservable();
    }
    private subject_AssetsLoaded = new BehaviorSubject<AssetDTO[]>(null);
    get observe_AssetsLoaded(): Observable<AssetDTO[]> {
        return this.subject_AssetsLoaded.asObservable();
    }
    private subject_AssetSearched = new BehaviorSubject<string>(null);
    get observe_AssetSearching(): Observable<string> {
        return this.subject_AssetSearched.asObservable();
    }
    private subject_AssetSelected = new BehaviorSubject<number>(null);
    get observe_AssetSelected(): Observable<number> {
        return this.subject_AssetSelected.asObservable();
    }
    private subject_AssetTypeSelected = new BehaviorSubject<AssetTypeEnum>(null);
    get observe_AssetTypeSelected(): Observable<AssetTypeEnum> {
        return this.subject_AssetTypeSelected.asObservable();
    }
    // private subject_AssetTypeUpdated = new BehaviorSubject<AssetTypeEnum>(null);
    // get observe_AssetTypeUpdated(): Observable<AssetTypeEnum> {
    //     return this.subject_AssetTypeUpdated.asObservable();
    // }
    private subject_AssetUpdated = new BehaviorSubject<number>(null);
    get observe_AssetUpdated(): Observable<number> {
        return this.subject_AssetUpdated.asObservable();
    }
    // private subject_EventSelected = new BehaviorSubject<number>(null);
    // get observe_EventSelected(): Observable<number> {
    //     return this.subject_EventSelected.asObservable();
    // }
    private subject_EventFocused = new BehaviorSubject<number>(null);
    get observe_EventFocused(): Observable<number> {
        return this.subject_EventFocused.asObservable();
    }
    private subject_EventPosted = new BehaviorSubject<EventDTO>(null);
    get observe_EventPosted(): Observable<EventDTO> {
        return this.subject_EventPosted.asObservable();
    }
    private subject_EventUpdated = new BehaviorSubject<number>(null);
    get observe_EventUpdated(): Observable<number> {
        return this.subject_EventUpdated.asObservable();
    }
    // private subject_EventLoaded = new BehaviorSubject<EventDTO>(null);
    // get observe_EventLoaded(): Observable<EventDTO> {
    //     return this.subject_EventLoaded.asObservable();
    // }
    // private subject_SymbolInfoLoaded = new BehaviorSubject<AssetIdentifierDTO>(null);
    // get observe_AssetIdentifierLoaded(): Observable<AssetIdentifierDTO> {
    //     return this.subject_SymbolInfoLoaded.asObservable();
    // }
    private subject_AssetEventsLoaded = new BehaviorSubject<AssetDTO>(null);
    get observe_AssetEventsLoaded(): Observable<AssetDTO> {
        return this.subject_AssetEventsLoaded.asObservable();
    }
    public portfolio$ = new CMNotifier<boolean>('portfolio', true);
    // private subject_PortfolioAssetsUpdated = new BehaviorSubject<boolean>(null);
    // get observe_PortfolioAssetsUpdated(): Observable<boolean> {
    //     return this.subject_PortfolioAssetsUpdated.asObservable();
    // }
    private subject_QuoteLoaded = new BehaviorSubject<IQuote>(null);
    get observe_QuoteLoaded(): Observable<IQuote> {
        return this.subject_QuoteLoaded.asObservable();
    }
    /* Setters */
    public setFocusedAsset(assetId: number) {
        // console.log('setFocusedAsset ' +  assetId);
        this.subject_AssetFocused.next(assetId);
    }
    public setFocusedEvent(eventId: number) {
        // console.log('setFocusedAsset ' +  assetId);
        this.subject_EventFocused.next(eventId);
    }
    // public setLoadedAssetInfo(dto: AssetInfoDTO) {
    //     this.subject_AssetInfoLoaded.next(dto);
    // }
    public setLoadedAsset(dto: AssetDTO) {
        this.subject_AssetLoaded.next(dto);
    }
    public setLoadedAssets(dtos: AssetDTO[]) {
        this.subject_AssetsLoaded.next(dtos);
    }
    // public setLoadedPortfolioAssets(dtos: AssetInfoDTO[]) {
    //     this.subject_PortfolioAssetsLoaded.next(dtos);
    // }
    public setLoadedAssetEvents(dto: AssetDTO) {
        this.subject_AssetEventsLoaded.next(dto);
    }
    public setLoadedAssetTotals(dto: AssetDTO) {
        this.subject_AssetTotalsLoaded.next(dto);
    }
    // public setLoadedAssetIdentifiers(dtos: AssetIdentifierDTO[]) {
    //     this.subject_AssetIdentifiersLoaded.next(dtos);
    // }
    // public setLoadedEvent(dto: EventDTO) {
    //     this.subject_EventLoaded.next(dto);
    // }
    // public setLoadedSymbolInfo(dto: AssetIdentifierDTO) {
    //     this.subject_SymbolInfoLoaded.next(dto);
    // }
    public setLoadedAllAssetTotals(dto: AssetDTO[]) {
        this.subject_AllAssetTotalsLoaded.next(dto);
    }
    public setLoadedQuote(dto: IQuote) {
        this.subject_QuoteLoaded.next(dto);
    }
    public setSearchedAsset(value: string) {
        this.subject_AssetSearched.next(value);
    }
    public setSelectedAsset(assetId: number) {
        this.subject_AssetSelected.next(assetId);
    }
    public setSelectedAssetType(assetType: AssetTypeEnum) {
        this.subject_AssetTypeSelected.next(assetType);
    }
    // public setSelectedEvent(eventId: number) {
    //     this.subject_EventSelected.next(eventId);
    // }
    public setUpdatedAsset(assetId: number) {
        // console.log('setUpdatedAsset ' +  assetId);
        this.subject_AssetUpdated.next(assetId);
    }
    // public setUpdatedAssetType(assetType: AssetTypeEnum) {
    //     // console.log('setUpdatedAsset ' +  assetId);
    //     this.subject_AssetTypeUpdated.next(assetType);
    // }
    public setUpdatedEvent(eventId: number) {
        // console.log('setUpdatedAsset ' +  assetId);
        this.subject_EventUpdated.next(eventId);
    }
    // public setUpdatedPortfolioAssets() {
    //     // console.log('setUpdatedAsset ' +  assetId);
    //     this.portfolio$.next(this.constructor.name, true);
    // }
    public setPostedAssetIdentifier(assetId: number) {
        this.subject_AssetIdentifierPosted.next(assetId);
    }
    public setPostedEvent(dto: EventDTO) {
        this.subject_EventPosted.next(dto);
    }



}

class CMObserver<T> {
    private subject = new BehaviorSubject<T>(undefined);
    public get observable(): Observable<T> {
        return this.subject.asObservable();
    }
    public next(value: T): void {
        this.subject.next(value);
    }
}

class CMSubscriber<T> {
    constructor(public appEventId: AppEventEnum, public logCalls: boolean) {
        this.observer = new CMObserver<T>();
    }
    private observer: CMObserver<T>;
    public next(value: T): void {
        if (this.logCalls) {
            console.log(`${AppEventEnum[this.appEventId]}.next()`);
        }
        this.observer.next(value);
    }
    public subscribe(callerName: string, callback: (value: T) => void): Subscription {
        return this.observer.observable.subscribe(value => {
            if (this.logCalls) {
                console.log(`${callerName}.observe ${AppEventEnum[this.appEventId]}`);
            }
            callback.call(this, value);
        });
    }
}

export enum AppEventEnum {
    eventFetched,
    eventSelected,
}
