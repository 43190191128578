import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BackEndService } from '../services/back-end.service';
import { PortfolioDMC } from './portfolio.dmc';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AssetDMC } from '../asset/asset.dmc';
import { CMSubscriptions } from '../utils/cm-notifiers';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit, OnDestroy {
  constructor(private services: BackEndService, private router: Router) { }
  private subscriptions = new CMSubscriptions();
  assetList = new MatTableDataSource<AssetListItem>([]);
  displayedColumns: string[] = ['symbol', 'cost', 'proceeds', 'value', 'change'];
  portfolio: PortfolioDMC;
  // subscribe_AssetList: Subscription;
  // subscribe_AssetUpdate: Subscription;
  showClosedPositions: boolean;
  showOpenPositions: boolean;
  get totalCost(): number {
    return 1000;
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.assetList.data = [];
    this.portfolio = this.services.model.application.portfolio;
    this.showOpenPositions = true;
    this.subscriptions.add('portfolio')
      .$ = this.services.broadcast.portfolio$
        .subscribe(this.constructor.name, b => this.assetDMCListToAssetList());
    this.subscriptions.add('asset')
      .$ = this.services.broadcast.observe_AssetUpdated
        .subscribe(assetId => this.onAssetUpdated(assetId));
    this.assetList.sort = this.sort;

  }
  onAssetUpdated(assetId: number) {
    if (assetId) {
      const dmc: AssetDMC = this.portfolio.getAsset(assetId);
      const listItem: AssetListItem = this.assetList.data.find(i => i.assetId === assetId);
      if (listItem) {
        this.mapAssetDMCToAssetListItem(dmc, listItem);
      }
    }
  }

  applyFilter(filterValue: string) {
    this.assetList.filter = filterValue.trim().toLowerCase();
  }
  assetDMCListToAssetList(): void {
    this.assetList.data = [];
    const newData: AssetListItem[] = [];
    const dmcs: AssetDMC[] = this.services.model.application.portfolio.assetDMCs;
    dmcs.forEach(dmc => {
      console.log(dmc);
      if (this.assetFilter(dmc)) {
        const listItem: AssetListItem = new AssetListItem();
        listItem.assetId = dmc.assetId;
        this.mapAssetDMCToAssetListItem(dmc, listItem);
        newData.push(listItem);
      }
    });
    const sortedArray: AssetListItem[] = newData.sort((n1, n2) => {
      if (n1.minDate > n2.minDate) {
        return -1;
      }

      if (n1.minDate < n2.minDate) {
        return 1;
      }
      return 0;
    });
    this.assetList.data = sortedArray;
  }
  assetFilter(dmc: AssetDMC): boolean {
    if (dmc.assetId === 1071) { return false; }
    if (dmc.totals.quantity > 0) {
      if (!this.showOpenPositions) { return false; }
    } else {
      if (!this.showClosedPositions) { return false; }
    }
    return true;
  }
  mapAssetDMCToAssetListItem(dmc: AssetDMC, listItem: AssetListItem) {
    // listItem.cash = dmc.dbTotals.totalCash;
    listItem.cost = - dmc.totals.totalCost;
    // listItem.maxDate = dmc.dbTotals.maxDate;
    listItem.minDate = dmc.totals.minDate;
    listItem.name = dmc.symbolInfo.name;
    listItem.proceeds = this.zeroToNada(dmc.totals.totalProceeds);
    // listItem.quantity = dmc.dbTotals.quantity;
    listItem.symbol = dmc.symbolInfo.symbol;
    listItem.value = this.zeroToNada(dmc.value);
    listItem.change = this.zeroToNada(dmc.change);
  }
  zeroToNada(amt: number) {
    if (!amt) { return undefined; }
    return amt;
  }
  onClickSymbol(assetId: number) {
    this.services.broadcast.setSelectedAsset(assetId);
    this.router.navigate(['/', 'stock']);
  }
  onToggleShowPositions() {
    this.assetDMCListToAssetList();
  }
  ngOnDestroy() {
    // this.subscribe_AssetList.unsubscribe();
    // this.subscribe_AssetUpdate.unsubscribe();
    this.subscriptions.unsubscribeAll();
  }

}
class AssetListItem {
  // cash: number;
  assetId: number;
  cost: number;
  // maxDate: string;
  minDate: string;
  name: string;
  proceeds: number;
  // quantity: number;
  symbol: string;
  value: number;
  change: number;
}
