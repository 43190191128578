<div>
    <app-asset-selector></app-asset-selector>
</div>
<div>
    <span><b>{{symbol}}</b></span>
    &nbsp;
    <span *ngIf="!editingName && name">
        <span>{{name}}</span>
        <button *ngIf="assetId" mat-icon-button color="primary" (click)="onClickEdit()">
            <mat-icon>edit</mat-icon>
        </button>
    </span>
    <span *ngIf="editingName">
        <div>
            <mat-form-field [ngStyle]="{'width' : nameWidthStyle()}">
                <input matInput [(ngModel)]="name">
            </mat-form-field>
            <button mat-icon-button color="accent" (click)="onClickSave()">
                <mat-icon>check</mat-icon>
            </button>
            <button mat-icon-button (click)="onClickCancel()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div>
            <mat-form-field>
                <input matInput [(ngModel)]="cusip" placeholder="CUSIP">
            </mat-form-field>
        </div>
    </span>
</div>
<div>
    <mat-accordion>
        <mat-expansion-panel *ngIf="showPositionPanel">
            <mat-expansion-panel-header>
                <mat-panel-title> Position </mat-panel-title>
            </mat-expansion-panel-header>
            <app-asset-position-info></app-asset-position-info>
            <app-asset-event-history></app-asset-event-history>
        </mat-expansion-panel>
        <mat-expansion-panel [(expanded)]="expandEventPanel">
            <mat-expansion-panel-header>
                <mat-panel-title> Transaction </mat-panel-title>
            </mat-expansion-panel-header>
            <app-event></app-event>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- <app-asset-position-info></app-asset-position-info>
    <app-asset-event-history></app-asset-event-history>
    <app-event></app-event> -->
</div>