import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { SettingsService } from './settings.service';
import { APIService } from './api.service';
import { BroadcastService } from './broadcast.service';
import { ModelService } from './model.service';
// import { DataService } from './data.service';

@Injectable()
export class BackEndService {
    constructor(private httpClient: HttpClient) {
        // this.settings = new SettingsService;
        this.broadcast = new BroadcastService();
        this.api = new APIService(httpClient, this.broadcast);
        // this.data = new DataService;
        this.model = new ModelService(
            this.api,
            // this.settings,
            this.broadcast,
            // this.data
        );
    }
    public api: APIService;
    public model: ModelService;
    // public settings: SettingsService;
    public broadcast: BroadcastService;
    // public data: DataService;

}


