import { APIService } from './api.service';
// import { SettingsService } from './settings.service';
import { BroadcastService } from './broadcast.service';
// import { DataService } from './data.service';
// import { TransactionModel } from '../models/transaction.model';
import { EventDMC } from '../event/event.dmc';
import { PortfolioDMC } from '../portfolio/portfolio.dmc';
import { ApplicationDMC } from '../application/application.dmc';

export class ModelService {
    constructor(
        private api: APIService,
        // private settings: SettingsService,
        private broadcast: BroadcastService,
        // private data: DataService,
    ) {
        this.application = new ApplicationDMC(
            this.broadcast,
            this.api
        );
        // this.api.getPortfolioAssets();
        // this.transaction = new TransactionModel(
        //     this.api,
        //     this.settings,
        //     this.broadcast,
        //     this.data
        // );
    }
    application: ApplicationDMC;
    // transaction: TransactionModel;
    // domainEvent: EventDMC;
    // portfolio: PortfolioDMC;
}
