import { APIService } from '../services/api.service';
import { IQuote } from '../dto/i-quote';
import { BroadcastService } from '../services/broadcast.service';
import { AssetDMC } from '../asset/asset.dmc';
// import { AssetInfoDTO } from '../dto/asset-info.dto';
// import { AssetIdentifierDTO } from '../dto/asset-identifier.dto';
// import { AssetEventsDTO } from '../dto/asset-events.dto';
import { EventDTO } from '../dto/event.dto';
import { AssetTotalsDTO } from '../dto/asset-totals.dto';
import { AssetDTO } from '../dto/asset-dto';

export class PortfolioDMC {
    constructor(private api: APIService, private broadcast: BroadcastService) {
        this.assetDMCs = [];
        // this.quoteRequests = [];

        // this.broadcast.observe_PortfolioAssetsLoaded
        //     .subscribe(dtos => this.onPortfolioAssetsLoaded(dtos));
        this.broadcast.observe_AssetsLoaded
            .subscribe(dtos => this.onAssetsLoaded(dtos));
        this.broadcast.observe_AssetEventsLoaded
            .subscribe(dto => this.onAssetEventsLoaded(dto));
        this.broadcast.observe_AssetFocused
            .subscribe(assetId => this.onAssetFocused(assetId));
        // this.broadcast.observe_AssetIdentifierLoaded
        //     .subscribe(dto => this.onAssetIdentifierLoaded(dto));
        this.broadcast.observe_AssetLoaded
            .subscribe(dto => this.onAssetLoaded(dto));
        this.broadcast.observe_AssetTotalsLoaded
            .subscribe(dto => this.onAssetTotalsLoaded(dto));
        this.broadcast.observe_AllAssetTotalsLoaded
            .subscribe(dtos => this.onAllAssetTotalsLoaded(dtos));

        this.broadcast.observe_QuoteLoaded
            .subscribe(dto => this.onQuoteLoaded(dto));
        // this.api.observe_AlphaVantageQuote
        //     .subscribe(dto => this.onLoadQuote(dto));
        // this.api.observe_IEXQuote
        //     .subscribe(dto => this.onLoadQuote(dto));

        this.broadcast.observe_EventPosted
            .subscribe(dto => this.onEventPosted(dto));
    }
    assetDMCs: AssetDMC[];
    // quoteRequests: string[];

    onEventPosted(dto: EventDTO) {
        // this.api.getAssetIdentifiers(); /* in case a new Asset was created */
        if (dto) {
            dto.assetIds.forEach(assetId => {
                const asset: AssetDMC = this.getAsset(assetId);
                if (asset) {
                    asset.onEventPosted(dto);
                } else {
                    this.api.getAsset(assetId);
                }
            });
        }
    }
    onAssetLoaded(dto: AssetDTO) {
        if (dto) {
            let asset: AssetDMC = this.getAsset(dto.assetId);
            if (!asset) {
                asset = this.createAssetDMC(dto);
                this.assetDMCs.push(asset);
                this.broadcast.setSelectedAsset(asset.assetId);
                this.api.getQuote(asset.symbolInfo.symbol);
            } else {
                asset.onAssetIdentifierLoaded(dto);
            }
        }
    }
    onAssetTotalsLoaded(dto: AssetDTO) {
        if (dto) {
            // console.log(dto);
            const asset: AssetDMC = this.getAsset(dto.assetId);
            asset.onAssetTotalsLoaded(dto.totals);
        }
    }
    onAssetEventsLoaded(dto: AssetDTO) {
        if (dto) {
            // console.log(dto);
            const asset: AssetDMC = this.getAsset(dto.assetId);
            asset.onAssetEventsLoaded(dto.events);
        }
    }
    onAssetFocused(assetId: number) {
        // console.log('PortfolioDMC.onAssetFocused ' + assetId);
        /* this handler intentionally at portfolio level and not ar asset level, where each asset would have to check if it's the target */
        if (assetId) {
            const asset: AssetDMC = this.getAsset(assetId);
            asset.onAssetFocused();
        }
    }
    // onAssetIdentifierLoaded(dto: AssetIdentifierDTO): void {
    //     if (dto) {
    //         const asset: AssetDMC = this.assetDMCs.find(a => a.assetId === dto.assetId);
    //         asset.onAssetIdentifierLoaded(dto);
    //     }
    // }

    getAsset(assetId: number): AssetDMC {
        const rv: AssetDMC = this.assetDMCs.find(a => a.assetId === assetId);
        return rv;
    }
    onAssetsLoaded(dtos: AssetDTO[]): void {
        if (dtos) {
            dtos.forEach(dto => {
                const assetDMC = this.createAssetDMC(dto);
                this.assetDMCs.push(assetDMC);
                // if (assetDMC.totals.quantity > 0) {
                //     this.quoteRequests.push(assetDMC.symbolInfo.symbol);
                // }
            });

            this.api.getAllAssetTotals();
        }
    }
    onAllAssetTotalsLoaded(dtos: AssetDTO[]): void {
        if (dtos) {
            dtos.forEach(dto => {
                const assetDMC = this.getAsset(dto.assetId);
                assetDMC.onAssetTotalsLoaded(dto.totals);
                if (assetDMC.totals.quantity > 0) {
                    this.api.getQuote(assetDMC.symbolInfo.symbol);
                }
            });
            this.broadcast.portfolio$.next(this.constructor.name, true);
        }
    }
    // onPortfolioAssetsLoaded(dtos: AssetInfoDTO[]): void {
    //     dtos.forEach(dto => {
    //         let assetDMC = this.assetDMCs.find(a => a.assetId === dto.assetId);
    //         if (!assetDMC) {
    //             assetDMC = this.createAssetDMC(dto);
    //             this.assetDMCs.push(assetDMC);
    //             if (assetDMC.totals.quantity > 0) {
    //                 this.quoteRequests.push(assetDMC.symbolInfo.symbol);
    //             }
    //         }
    //         if (assetDMC.totals.quantity > 0) {
    //             this.api.getQuote(assetDMC.symbolInfo.symbol);
    //         }
    //     });
    //     this.broadcast.setUpdatedPortfolioAssets();
    //     // this.getNextQuote();
    // }
    createAssetDMC(dto: AssetDTO): AssetDMC {
        const dmc: AssetDMC = new AssetDMC(this.api, this.broadcast);
        dmc.assetId = dto.assetId;
        dmc.assetType = dto.assetType;
        dmc.symbolInfo = dto.identifiers.find(i => i.active);
        // dmc.totals = dto.totals;
        return dmc;
    }
    onQuoteLoaded(quote: IQuote): void {
        if (quote) {
            // console.log(quote);
            const asset: AssetDMC = this.assetDMCs.find(a => a.symbolInfo.symbol === quote.symbol);
            if (asset) {
                asset.onQuoteLoaded(quote);
            }
        }
    }
    // getNextQuote() {
    //     if (this.quoteRequests.length > 0) {
    //         const nextSymbol = this.quoteRequests[0];
    //         // if (this.mutualFundSymbols.includes(nextSymbol)) {
    //         //     setTimeout(() => {
    //         //         this.api.getAlphaVantageQuote(nextSymbol);
    //         //     }, 12000);
    //         // } else {
    //         //     this.api.getIEXQuote(nextSymbol);
    //         // }
    //         this.api.getQuote(nextSymbol);
    //     }
    // }
}
