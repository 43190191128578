import { AssetTotalsDTO } from '../dto/asset-totals.dto';
import { APIService } from '../services/api.service';
import { AssetIdentifierDTO } from '../dto/asset-identifier.dto';
import { EventSummaryDTO } from '../dto/event-summary.dto';
import { BroadcastService } from '../services/broadcast.service';
import { EventDTO } from '../dto/event.dto';
// import { AssetInfoDTO } from '../dto/asset-info.dto';
import { IQuote } from '../dto/i-quote';
import { AssetDTO } from '../dto/asset-dto';

export class AssetDMC {
    constructor(private api: APIService, private broadcast: BroadcastService) {
        this.events = [];
        this.totals = new AssetTotalsDTO();
        this.symbolInfo = new AssetIdentifierDTO;
        this.price = 0;
    }

    get cost(): number { return this.totals.totalCost; }
    get change(): number {
        return ((this.value + this.totals.totalProceeds) / -this.totals.totalCost * 100) - 100;
    }
    get proceeds(): number { return this.totals.totalProceeds; }
    get quantity(): number { return this.totals.quantity; }
    get value(): number { return this.totals.quantity * this.price; }

    assetId: number;
    assetType: AssetTypeEnum;
    price: number;

    // symbol: string;
    totals: AssetTotalsDTO;
    symbolInfo: AssetIdentifierDTO;

    events: EventSummaryDTO[];

    postAssetIdentifier() {
        this.api.postAssetIdentifier(this.symbolInfo);
    }
    onAssetEventsLoaded(dtos: EventSummaryDTO[]) {
        this.events = dtos;
        this.broadcast.setUpdatedAsset(this.assetId);
    }
    onAssetFocused() {
        this.api.getAssetEvents(this.assetId);
    }
    onAssetIdentifierLoaded(dto: AssetDTO) {
        this.symbolInfo = dto.identifiers[0];
        this.broadcast.setUpdatedAsset(this.assetId);
    }
    onAssetTotalsLoaded(dto: AssetTotalsDTO) {
        this.totals = dto;
        this.broadcast.setUpdatedAsset(this.assetId);
    }
    onEventPosted(dto: EventDTO) {
        this.api.getAssetTotals(this.assetId);
        this.api.getAssetEvents(this.assetId);
    }
    onQuoteLoaded(dto: IQuote) {
        this.price = dto.price;
        this.broadcast.setUpdatedAsset(this.assetId);
    }
}
export enum AssetTypeEnum {
    Market = 1,
    Cash = 2,
}
