export class AssetTotalsDTO {
    symbol: string;
    name: string;
    assetId: number;
    quantity: number;
    minDate: string;
    maxDate: string;
    totalCost: number;
    totalProceeds: number;
    totalCash: number;
}
