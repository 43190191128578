import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable, of } from "rxjs";
import { AssetIdentifierDTO } from "../dto/asset-identifier.dto";
// import { TransactionDTO } from '../dto/transaction-dto';
// import { AssetInfoDTO } from '../dto/asset-info.dto';
import { CashPositionInfoDTO } from "../dto/cash-position-info-dto";
import { EventDTO } from "../dto/event.dto";
import { AlphaVantageQuoteDTO } from "../dto/alpha-vantage-quote.dto";
import { IEXTradingQuoteDTO } from "../dto/iex-trading-quote.dto";
import { BroadcastService } from "./broadcast.service";
import { AssetDTO } from "../dto/asset-dto";
import { CMSubscriptions, CMNotifier } from "../utils/cm-notifiers";

export class APIService {
  constructor(
    private httpClient: HttpClient,
    private broadcast: BroadcastService
  ) {
    // this.baseAPIUrl = `https://localhost:44325/api/`;
    this.baseAPIUrl = `https://stocks.api.xvonrad.be/api/`;
    // this.observe_AlphaVantageQuote.subscribe(q => this.onAlphaVantageQuoteLoaded(q));
    // this.observe_IEXQuote.subscribe(q => this.onIEXQuoteLoaded(q));
    this.subscriptions.add(
      "alpha-vantage-quote"
    ).$ = this.alphaVantage$.subscribe(this.constructor.name, (q) =>
      this.onAlphaVantageQuoteLoaded(q)
    );
    this.subscriptions.add("iex-quote").$ = this.iex$.subscribe(
      this.constructor.name,
      (q) => this.onIEXQuoteLoaded(q)
    );
  }
  private subscriptions = new CMSubscriptions();
  private baseAPIUrl: string;

  private forAlphaVantageSymbols = [
    "LLPFX",
    "TORYX",
    "MCGFX",
    "TASCX",
    "SMGIX",
    "VWDRY",
    "BYDDY",
    "DASTY",
    "TCEHY",
    "NLLSY",
    "AIQUY",
    "DNNGY"
  ];
  private alphaVantageWaitingList: string[] = [];
  private alphaVantageBusy: boolean;
  private iexWaitingList: string[] = [];
  private iexBusy: boolean;

  private cashPositionInfoSubject = new BehaviorSubject<CashPositionInfoDTO>(
    new CashPositionInfoDTO()
  );
  // private subject_SymbolSearchList = new BehaviorSubject<SearchListItemDTO[]>([]);
  private alphaVantage$ = new CMNotifier<AlphaVantageQuoteDTO>(
    "AlphaVantageQuoteFetched",
    false
  );
  private iex$ = new CMNotifier<IEXTradingQuoteDTO>("IEXQuoteFetched", false);

  // private subject_AlphaVantageQuoteLoaded = new BehaviorSubject<AlphaVantageQuoteDTO>(null);
  // get observe_AlphaVantageQuote(): Observable<AlphaVantageQuoteDTO> {
  //     return this.subject_AlphaVantageQuoteLoaded.asObservable();
  // }
  // private subject_IEXQuoteLoaded = new BehaviorSubject<IEXTradingQuoteDTO>(null);
  // get observe_IEXQuote(): Observable<IEXTradingQuoteDTO> {
  //     return this.subject_IEXQuoteLoaded.asObservable();
  // }

  get obsCashPositionInfo(): Observable<CashPositionInfoDTO> {
    return this.cashPositionInfoSubject.asObservable();
  }
  // get obsStockSymbols(): Observable<SearchListItemDTO[]> {
  //     return this.subject_SymbolSearchList.asObservable();
  // }

  postEvent(dto: EventDTO, symbol: string): void {
    // console.log(dto);
    const apiUrl = this.baseAPIUrl + "Transaction/PostDomainEvent";
    this.httpClient.post<EventDTO>(apiUrl, dto).subscribe(
      (evnt) => {
        this.broadcast.setPostedEvent(evnt);
      },
      (err) => console.log(err)
    );
  }
  getEvent(eventId: number): void {
    const apiUrl =
      this.baseAPIUrl + "Transaction/GetDomainEvent?eventId=" + eventId;
    this.httpClient.get<EventDTO>(apiUrl).subscribe(
      (evnt) => {
        this.broadcast.eventFetched.next(evnt);
      },
      (err) => console.log(err)
    );
  }
  // getPortfolioAssets(): void {
  //     const apiUrl = this.baseAPIUrl + 'StockSymbol/GetAssetInfo_All';
  //     this.httpClient.get<AssetInfoDTO[]>(apiUrl)
  //         .subscribe(
  //             (rslt) => {
  //                 this.broadcast.setLoadedPortfolioAssets(rslt);
  //             },
  //             (err) => console.log(err),
  //         );
  // }
  getAssets(): void {
    const apiUrl = this.baseAPIUrl + "Asset/GetAssets";
    this.httpClient.get<AssetDTO[]>(apiUrl).subscribe(
      (dtos) => {
        this.broadcast.setLoadedAssets(dtos);
      },
      (err) => console.log(err)
    );
  }
  // getAssetIdentifiers(): void {
  //     const apiUrl = this.baseAPIUrl + 'Asset/GetAssetIdentifiers';
  //     this.httpClient.get<AssetIdentifierDTO[]>(apiUrl)
  //         .subscribe(
  //             (dtos) => {
  //                 this.broadcast.setLoadedAssetIdentifiers(dtos);
  //             },
  //             (err) => console.log(err),
  //         );
  // }
  getQuote(symbol: string): void {
    if (this.forAlphaVantageSymbols.includes(symbol)) {
      this.alphaVantageWaitingList.push(symbol);
      this.getAlphaVantageQuotes();
    } else {
      this.iexWaitingList.push(symbol);
      this.getIEXQuotes();
    }
  }
  private getIEXQuotes() {
    if (!this.iexBusy) {
      this.iexBusy = true;
      this.getIEXQuote(this.iexWaitingList[0]);
    }
  }
  private getIEXQuote(symbol: string): void {
    const apiUrl = this.baseAPIUrl + "StockSymbol/GetIEXQuote?symbol=" + symbol;
    this.httpClient.get<IEXTradingQuoteDTO>(apiUrl).subscribe(
      (rslt) => {
        this.iex$.next(this.constructor.name, rslt);
      },
      (err) => console.log(err)
    );
  }
  private onIEXQuoteLoaded(quote: IEXTradingQuoteDTO) {
    if (quote) {
      this.broadcast.setLoadedQuote(quote);
      this.iexWaitingList.splice(0, 1);
      if (this.iexWaitingList.length > 0) {
        this.getIEXQuote(this.iexWaitingList[0]);
      } else {
        this.iexBusy = false;
      }
    }
  }
  private getAlphaVantageQuotes() {
    if (!this.alphaVantageBusy) {
      this.alphaVantageBusy = true;
      this.getAlphaVantageQuote(this.alphaVantageWaitingList[0]);
    }
  }
  private getAlphaVantageQuote(symbol: string): void {
    const apiUrl = this.baseAPIUrl + "StockSymbol/GetQuote?symbol=" + symbol;
    this.httpClient.get<AlphaVantageQuoteDTO>(apiUrl).subscribe(
      (rslt) => {
        this.alphaVantage$.next(this.constructor.name, rslt);
      },
      (err) => console.log(err)
    );
  }
  private onAlphaVantageQuoteLoaded(quote: IEXTradingQuoteDTO) {
    if (quote) {
      this.broadcast.setLoadedQuote(quote);
      this.alphaVantageWaitingList.splice(0, 1);
      if (this.alphaVantageWaitingList.length > 0) {
        setTimeout(() => {
          this.getAlphaVantageQuote(this.alphaVantageWaitingList[0]);
        }, 12000);
      } else {
        this.alphaVantageBusy = false;
      }
    }
  }
  // postNewTransaction(dto: TransactionDTO) {
  //     // console.log(dto);
  //     const apiUrl = this.baseAPIUrl + 'Transaction/New';
  //     this.httpClient.post<TransactionDTO>(apiUrl, dto)
  //         .subscribe(
  //             (res) => {
  //                 this.onPostedTransaction(dto.symbol);
  //             },
  //             (err) => console.log(err),
  //         );
  // }
  // postTransactionEdit(dto: TransactionDTO) {
  //     const apiUrl = this.baseAPIUrl + 'Transaction/Edit';
  //     this.httpClient.post<TransactionDTO>(apiUrl, dto)
  //         .subscribe(
  //             (res) => {
  //                 this.onPostedTransaction(dto.symbol);
  //             },
  //             (err) => console.log(err),
  //         );
  // }
  postAssetIdentifier(dto: AssetIdentifierDTO) {
    const apiUrl = this.baseAPIUrl + "Asset/PostAssetIdentifier";
    this.httpClient.post<AssetIdentifierDTO>(apiUrl, dto).subscribe(
      (res) => {
        this.broadcast.setPostedAssetIdentifier(res.assetId);
      },
      (err) => console.log(err)
    );
  }
  onPostedTransaction(symbol: string) {
    // this.getAssetInfo(symbol);
    // this.getStockPositionInfoDTO(symbol);
    // this.getCashPositionInfoDTO();
    // this.getPositionHistory(symbol);
    // this.getEventHistory(symbol);
    // this.getAllSymbols();
  }
  getAsset(assetId: number): void {
    const apiUrl = this.baseAPIUrl + "Asset/GetAsset?assetId=" + assetId;
    this.httpClient
      .get<AssetDTO>(apiUrl)
      .toPromise()
      .then((rv) => {
        this.broadcast.setLoadedAsset(rv);
      });
  }
  // getAssetInfo(assetId: number): void {
  //     const apiUrl = this.baseAPIUrl + 'Asset/GetAssetInfo?assetId=' + assetId;
  //     this.httpClient
  //         .get<AssetInfoDTO>(apiUrl)
  //         .toPromise()
  //         .then(rv => {
  //             this.broadcast.setLoadedAssetInfo(rv);
  //         });
  // }
  getAllAssetTotals(): void {
    const apiUrl = this.baseAPIUrl + "Asset/GetAllAssetTotals";
    this.httpClient
      .get<AssetDTO[]>(apiUrl)
      .toPromise()
      .then((rv) => {
        this.broadcast.setLoadedAllAssetTotals(rv);
      });
  }
  getAssetTotals(assetId: number): void {
    const apiUrl = this.baseAPIUrl + "Asset/GetAssetTotals?assetId=" + assetId;
    this.httpClient
      .get<AssetDTO>(apiUrl)
      .toPromise()
      .then((rv) => {
        this.broadcast.setLoadedAssetTotals(rv);
      });
  }

  getCashPositionInfoDTO(): void {
    const apiUrl = this.baseAPIUrl + "Cash/GetCashPositionInfo";
    this.httpClient
      .get<CashPositionInfoDTO>(apiUrl)
      .toPromise()
      .then((rv) => this.cashPositionInfoSubject.next(rv));
  }

  // getAllSymbols(): void {
  //     const apiUrl = this.baseAPIUrl + 'StockSymbol/GetAllSymbols';
  //     this.httpClient
  //         .get<SearchListItemDTO[]>(apiUrl)
  //         .toPromise()
  //         .then(rv => this.subject_SymbolSearchList.next(rv)
  //         );
  // }
  // getTransaction(symbol: string, eventId: number): Promise<TransactionDTO> {
  //     const apiUrl = this.baseAPIUrl + 'Transaction/Get?symbol=' + symbol + '&eventId=' + eventId;
  //     return this.httpClient
  //         .get<TransactionDTO>(apiUrl)
  //         .toPromise();
  // }
  getAssetEvents(assetId: number): void {
    const apiUrl = this.baseAPIUrl + "Asset/GetAssetEvents?assetId=" + assetId;
    this.httpClient
      .get<AssetDTO>(apiUrl)
      .toPromise()
      .then((rv) => this.broadcast.setLoadedAssetEvents(rv));
  }
}
