import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { BackEndService } from '../services/back-end.service';

@Component({
  selector: 'app-asset-position-info',
  templateUrl: './asset-position-info.component.html'
})
export class AssetPositionInfoComponent implements OnInit, OnDestroy {
  constructor(private services: BackEndService) { }

  subscribe_AssetFocused: Subscription;
  subscribe_AssetUpdated: Subscription;
  // assetDMC: AssetDMC;
  quantity: number;
  // stockPositionInfo: StockPositionInfoDTO;
  // stockPositionInfoSubscription: Subscription;
  ngOnInit(): void {
    this.subscribe_AssetFocused = this.services.broadcast
      .observe_AssetFocused
      .subscribe(assetId => this.onAssetUpdated());
    this.subscribe_AssetUpdated = this.services.broadcast
      .observe_AssetUpdated
      .subscribe(assetId => this.onAssetUpdated());
  }
  // onAssetSelected(assetId: number): void {
  //   this.assetDMC = this.services.model.portfolio.getAsset(assetId);
  //   this.onAssetUpdated(assetId);
  // }
  onAssetUpdated(): void {
      this.quantity = this.services.model.application.focusedAsset.quantity;
  }
  ngOnDestroy() {
    this.subscribe_AssetUpdated.unsubscribe();
    this.subscribe_AssetFocused.unsubscribe();
  }
}
