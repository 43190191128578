import { EventDTO } from '../dto/event.dto';
import { BookingDMC } from '../booking/booking.dmc';
import { EventTypeEnum, EditMode, BookingTypeEnum } from '../dto/enums';
import { BroadcastService } from '../services/broadcast.service';
import { APIService } from '../services/api.service';
import { BookingDTO } from '../dto/booking.dto';
import { BehaviorSubject } from 'rxjs';
import { AssetTypeEnum } from '../asset/asset.dmc';

// import * as moment from 'moment';


export class EventDMC {
    constructor(
        private broadcast: BroadcastService,
        private api: APIService,
    ) {
        this.subject_BookingChange = new BehaviorSubject<boolean>(false);
        this.broadcast.observe_AssetFocused
            .subscribe(assetId => this.onAssetFocused());
        this.broadcast.eventSelected
            .subscribe('EventDMC', eventId => this.onEventSelected(eventId));
        this.broadcast.eventFetched
            .subscribe('EventDMC', dto => this.onEventFetched(dto));

        this.eventDate = new Date();
        this.eventType = EventTypeEnum.Dividend;
        this.initializeDTO();
        this.onChangeEventType();
    }
    public eventId: number;

    public eventDate: Date;
    public eventType: EventTypeEnum;
    public bookings: BookingDMC[];
    public dto: EventDTO;
    public selectedSymbol: string;
    public subject_BookingChange: BehaviorSubject<boolean>;
    // public assetType: AssetTypeEnum;

    get editMode(): EditMode {
        if (isNaN(this.dto.eventId)) {
            return EditMode.New;
        } else {
            return EditMode.Modify;
        }
    }
    onEventSelected(eventId: number) {
        if (eventId) {
            this.api.getEvent(eventId);
        }
    }
    // onAssetTypeSelected(assetType: AssetTypeEnum) {
    //     this.assetType = assetType;
    // }
    onAssetFocused() {
        this.onChangeEventType();
    }
    getBookingTypes(): BookingTypeEnum[] {
        /* EXTRA BOOKING TYPES AVAILABLE PER DOMAIN EVENT TYPE */
        let rv: BookingTypeEnum[];
        switch (this.eventType) {
            case EventTypeEnum.Dividend:
                rv = [
                    BookingTypeEnum.Reinvest,
                    BookingTypeEnum.WithheldTax,
                    BookingTypeEnum.ADRFee,
                ];
                break;
            case EventTypeEnum.SpinOff:
                rv = [
                    BookingTypeEnum.CIL
                ];
                break;
            case EventTypeEnum.Split:
                rv = [
                    BookingTypeEnum.CIL
                ];
                break;
            case EventTypeEnum.Merger:
                rv = [
                    BookingTypeEnum.Acquire,
                    BookingTypeEnum.CIL,
                ];
                break;
            case EventTypeEnum.Purchase:
                rv = [
                    BookingTypeEnum.PCommission,

                ];
                break;
            case EventTypeEnum.Sale:
                rv = [
                    BookingTypeEnum.SCommission,

                ];
                break;
            case EventTypeEnum.Withdrawal:
                rv = [
                    BookingTypeEnum.BankingFee,
                ];
                break;
            default:
                rv = [];
        }
        return rv;
    }
    getEventTypes(assetType: AssetTypeEnum): EventTypeEnum[] {
        let rv: EventTypeEnum[];
        switch (assetType) {
            case AssetTypeEnum.Cash:
                rv = [
                    EventTypeEnum.Deposit,
                    EventTypeEnum.Fee,
                    EventTypeEnum.Interest,
                    EventTypeEnum.Withdrawal,
                ];
                break;
            case AssetTypeEnum.Market:
                rv = [
                    EventTypeEnum.Delisting,
                    EventTypeEnum.Dividend,
                    EventTypeEnum.Fee,
                    EventTypeEnum.Merger,
                    EventTypeEnum.Purchase,
                    EventTypeEnum.Sale,
                    EventTypeEnum.SpinOff,
                    EventTypeEnum.Split,
                ];
                break;
        }
        // rv = [
        //     EventTypeEnum.Delisting,
        //     EventTypeEnum.Dividend,
        //     EventTypeEnum.Fee,
        //     EventTypeEnum.Merger,
        //     EventTypeEnum.Purchase,
        //     EventTypeEnum.Sale,
        //     EventTypeEnum.SpinOff,
        //     EventTypeEnum.Split,
        //     EventTypeEnum.Deposit,
        //     EventTypeEnum.Interest,
        //     EventTypeEnum.Withdrawal,
        // ];
        return rv;
    }

    private createBooking(bookingType: BookingTypeEnum): BookingDMC {
        const booking: BookingDMC = new BookingDMC(this);
        booking.dto.bookingType = bookingType;
        this.addBooking(booking);
        return booking;
    }
    private addBooking(booking: BookingDMC) {
        this.bookings.push(booking);
        booking.configure();
    }
    private convertToBooking(dto: BookingDTO): void {
        const booking: BookingDMC = new BookingDMC(this);
        booking.dto = dto;
        this.addBooking(booking);
        booking.subject_DTOChanges.next(dto);
        // return booking;
    }

    getProceeds(): number {
        let rv = 0;
        this.bookings
            .forEach(b => rv += b.getProceeds());
        return rv;
    }
    private configure() {
        switch (this.editMode) {
            case EditMode.New:
                switch (this.eventType) {
                    case EventTypeEnum.Delisting:
                        this.createBooking(BookingTypeEnum.Acquire);
                        break;
                    case EventTypeEnum.Dividend:
                        this.createBooking(BookingTypeEnum.Yield);
                        this.createBooking(BookingTypeEnum.Reinvest);
                        break;
                    case EventTypeEnum.Fee:
                        this.createBooking(BookingTypeEnum.ADRFee);
                        break;
                    case EventTypeEnum.Interest:
                        this.createBooking(BookingTypeEnum.Yield);
                        break;
                    case EventTypeEnum.Merger:
                        this.createBooking(BookingTypeEnum.Sale);
                        break;
                    case EventTypeEnum.Purchase:
                        this.createBooking(BookingTypeEnum.Acquire);

                        break;
                    case EventTypeEnum.Sale:
                        this.createBooking(BookingTypeEnum.Sale);

                        break;
                    case EventTypeEnum.SpinOff:
                        this.createBooking(BookingTypeEnum.ReduceCost);
                        this.createBooking(BookingTypeEnum.Acquire);
                        break;
                    case EventTypeEnum.Split:
                        this.createBooking(BookingTypeEnum.Acquire);
                        break;
                    case EventTypeEnum.Withdrawal:
                        this.createBooking(BookingTypeEnum.CashOutflow);
                        break;
                }
                break;
            case EditMode.Modify:
                this.dto.bookings
                    .forEach(b => {
                        this.convertToBooking(b);
                    });
                break;
        }
    }
    initializeDTO() {
        this.dto = new EventDTO();
    }

    public onChangeEventType() {
        this.removeBookings();
        this.initializeDTO();
        this.configure();
    }
    onEventFetched(dto: EventDTO): void {
        if (dto) {
            this.removeBookings();
            this.dto = dto;
            this.eventType = dto.eventType;
            if (this.dto.bookings && this.dto.bookings[0]) {
                this.eventDate = new Date(this.dto.bookings[0].bookingDate);
            }
            this.configure();
        }
    }
    onToggleBookingType(bookingType: BookingTypeEnum) {
        const x = this.bookings.findIndex(b => b.dto.bookingType === bookingType);
        if (x >= 0) {
            this.bookings[x].unsubscribe();
            this.bookings.splice(x, 1);
        } else {
            this.createBooking(bookingType);
            this.subject_BookingChange.next(true);
        }
    }
    private removeBookings() {
        if (this.bookings) {
            this.bookings
                .forEach(b => b.unsubscribe());
        }
        this.bookings = [];
    }
    save() {
        if (!this.selectedSymbol) {
            throw new Error('No Symbol selected');
        }
        switch (this.editMode) {
            case EditMode.New:
                this.dto.eventType = this.eventType;
                break;
            case EditMode.Modify:
                if (this.dto.eventType !== this.eventType) {
                    throw new Error('Event type change not supported');
                }
        }
        this.dto.bookings = [];
        this.bookings.forEach(b => {
            let bookingDate: Date;
            // bookingDate.
            // if (b.dto.bookingDate) {
            //     bookingDate = b.dto.bookingDate.toString().substr(0, 10);
            // } else {
            //     bookingDate = this.eventDate.toString().substr(0, 10);
            // }
            if (b.dto.bookingDate) {
                bookingDate = b.bookingDate;
            } else {
                bookingDate = this.eventDate;
            }
            if (!b.dto.bookingDate) {
                b.bookingDate = bookingDate;
            }
            if (!b.dto.symbol) {
                b.dto.symbol = this.selectedSymbol;
            }
            this.dto.bookings.push(b.dto);
        });
        // console.log(this.dto);
        this.api.postEvent(this.dto, this.selectedSymbol);
        this.onChangeEventType();
    }
}
