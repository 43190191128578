import { Component, OnInit, OnDestroy} from '@angular/core';
import { EventTypeEnum, BookingTypeEnum } from '../dto/enums';
import { EventDMC } from './event.dmc';
import { BackEndService } from '../services/back-end.service';
import { Subscription } from 'rxjs';
import { AssetTypeEnum } from '../asset/asset.dmc';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
})
export class EventComponent implements OnInit, OnDestroy {

  constructor(private services: BackEndService) { }

  eventTypeEnumeration = EventTypeEnum;
  eventTypes: EventTypeEnum[];
  bookingTypeEnumeration = BookingTypeEnum;
  // bookingTypes: BookingTypeEnum[];

  event: EventDMC;
  subscribe_EventFocused: Subscription;
  subscribe_AssetUpdated: Subscription;
  subscribe_AssetFocused: Subscription;
  ngOnInit() {
    this.subscribe_EventFocused = this.services.broadcast
      .observe_EventFocused.subscribe(eventId => this.onEventFocused());
    this.subscribe_AssetUpdated = this.services.broadcast
      .observe_AssetUpdated.subscribe(eventId => this.onAssetUpdated());
    this.subscribe_AssetFocused = this.services.broadcast
      .observe_AssetFocused.subscribe(assetType => this.onAssetFocused());
  }
  onEventFocused() {
    this.event = this.services.model.application.focusedEvent;
    this.eventTypes = this.event.getEventTypes(this.services.model.application.focusedAsset.assetType);
  }
  onAssetUpdated() {
    this.event.selectedSymbol = this.services.model.application.focusedAsset.symbolInfo.symbol;
  }
  onAssetFocused() {
    const assetType: AssetTypeEnum = this.services.model.application.focusedAsset.assetType;
    this.eventTypes = this.event.getEventTypes(assetType);
    if (!this.eventTypes.includes(this.event.eventType)) {
      switch (assetType) {
        case AssetTypeEnum.Cash:
          this.event.eventType = EventTypeEnum.Interest;
          break;
        case AssetTypeEnum.Market:
          this.event.eventType = EventTypeEnum.Dividend;
          break;
      }
    }
  }
  onChangeEventType() {
    this.event.onChangeEventType();

  }
  onClickCancelEvent() { }
  onClickMenu(bookingType: BookingTypeEnum) {
    this.event.onToggleBookingType(bookingType);
  }
  onClickSaveEvent() {
    this.event.save();
  }
  ngOnDestroy() {
    this.subscribe_EventFocused.unsubscribe();
    this.subscribe_AssetUpdated.unsubscribe();
    this.subscribe_AssetFocused.unsubscribe();
  }
}
