<form>
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input type="text" placeholder="Symbol, name..." matInput [formControl]="inputControl"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" [panelWidth]="auto">
            <mat-option *ngFor="let option of filteredSymbols | async" [value]="option.value">
                {{option.display}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>