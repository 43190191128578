export enum EventTypeEnum {
    Purchase = 1,
    Sale = 2,
    Dividend = 3,
    Merger = 4,
    SpinOff = 5,
    Split = 6,
    Deposit = 7,
    Withdrawal = 8,
    Interest = 9,
    Transfer = 10,
    Fee = 11,
    Delisting = 12,
}
export enum BookingTypeEnum {
    Acquire = 1,
    Sale = 2,
    Yield = 3,
    Reinvest = 4,
    PCommission = 5,
    WithheldTax = 6,
    CashInflow = 7,
    CashOutflow = 8,
    ReduceCost = 9,
    ADRFee = 10,
    SCommission = 11,
    BankingFee = 12,
    CIL = 13,
}
export enum EditMode {
    New, Modify,
}


