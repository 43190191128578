import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { BackEndService } from '../services/back-end.service';
import { AssetDTO } from '../dto/asset-dto';

@Component({
    selector: 'app-asset-selector',
    templateUrl: './asset-selector.component.html'
})
export class AssetSelectorComponent implements OnInit, OnDestroy {
    constructor(private services: BackEndService) { }

    subscribe_AssetsLoaded: Subscription;
    subscribe_AssetLoaded: Subscription;
    subcribe_InputValueChange: Subscription;
    searchList: SearchListItem[] = [];
    filteredSymbols: Observable<SearchListItem[]>;
    inputControl = new FormControl();

    displayFn(item?: SearchListItem): string | undefined {
        return item ? item.display : undefined;
    }

    ngOnInit(): void {
        this.filteredSymbols = this.inputControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.display),
                map(display => this._filter(display))
            );
        this.subcribe_InputValueChange = this.inputControl
            .valueChanges
            .subscribe(value => {
                this.services.broadcast.setSearchedAsset(value);
            });

        // this.subscribe_SymbolList = this.services.api
        //     .obsStockSymbols
        //     .subscribe(list => {
        //         this.searchList = list;
        //     });
        this.subscribe_AssetsLoaded = this.services.broadcast
            .observe_AssetsLoaded
            .subscribe(dtos => this.onAssetsLoaded(dtos));
        this.subscribe_AssetLoaded = this.services.broadcast
            .observe_AssetLoaded
            .subscribe(dto => this.onAssetLoaded(dto));
        //  this.services.api.getAllSymbols();
    }

    onAssetsLoaded(dtos: AssetDTO[]): void {
        if (dtos) {
            const list: SearchListItem[] = [];
            dtos.forEach(dto => {
                dto.identifiers.forEach(id => {
                    if (!list.find(itm => itm.display === id.symbol)) {
                        list.push({ 'value': dto.assetId, 'display': id.symbol });
                    }
                    if (!list.find(itm => itm.display === id.name)) {
                        list.push({ 'value': dto.assetId, 'display': id.name });
                    }
                });
            });
            this.searchList = list;
        }
    }
    onAssetLoaded(dto: AssetDTO): void {
        if (dto) {
            const list = this.searchList;
            if (!list.find(itm => itm.display === dto.identifiers[0].symbol)) {
                list.push({ 'value': dto.assetId, 'display': dto.identifiers[0].symbol });
            }
            if (!list.find(itm => itm.display === dto.identifiers[0].name)) {
                list.push({ 'value': dto.assetId, 'display': dto.identifiers[0].name });
            }
            this.searchList = list;
        }
    }
    private _filter(value: string): SearchListItem[] {
        if (value) {
            const filterValue = value.toUpperCase();
            return this.searchList.filter(option => option.display.toUpperCase().includes(filterValue));
        }
    }

    ngOnDestroy() {
        // don't forget to unsubscribe and free some memory
        this.subscribe_AssetsLoaded.unsubscribe();
        this.subcribe_InputValueChange.unsubscribe();
        this.subscribe_AssetLoaded.unsubscribe();
    }
}
class SearchListItem {
    value: number;
    display: string;
}
