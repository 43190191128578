<h2>Cash</h2>
<div>
    <app-cash-position-info></app-cash-position-info>
</div>
<br>

    <!-- <app-transaction></app-transaction> -->
    <!-- <app-event></app-event> -->

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title> Transaction </mat-panel-title>
        </mat-expansion-panel-header>
        <app-event></app-event>
    </mat-expansion-panel>
</mat-accordion>