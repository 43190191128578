<div>
    <span><b>{{bookingTypeEnumeration[booking.dto.bookingType]}}</b></span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let field of booking.hiddenFields" (click)="onClickMenu(field)">
            {{field}}</button>
    </mat-menu>
</div>
<div *ngIf="booking.show.date">
    <mat-form-field>
        <input matInput [matDatepicker]="myDatepicker" placeholder="Date" [(ngModel)]="booking.bookingDate">
        <mat-datepicker #myDatepicker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
    </mat-form-field>
</div>
<div *ngIf="booking.show.symbol">
    <mat-form-field>
        <input matInput type="text" placeholder="Symbol" [(ngModel)]="booking.dto.symbol">
    </mat-form-field>
</div>
<div>
    <mat-form-field *ngIf="booking.show.quantity">
        <input matInput type="number" placeholder="Quantity" [(ngModel)]="quantity"
            [readonly]="booking.readOnly.quantity" (keyup)="onKeyUp()">
    </mat-form-field>
    <mat-form-field *ngIf="booking.show.price">
        <input matInput type="number" placeholder="Price" [(ngModel)]="price" [readonly]="booking.readOnly.price"
            (keyup)="onKeyUp()" (dblclick)="onDblClick('price')">
    </mat-form-field>
    <mat-form-field *ngIf="booking.show.amount">
        <input matInput type="number" placeholder="Amount" [(ngModel)]="amount" [readonly]="booking.readOnly.amount"
            (keyup)="onKeyUp()">
    </mat-form-field>
</div>