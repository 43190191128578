import { BookingDTO } from '../dto/booking.dto';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs';
import { BookingTypeEnum, EventTypeEnum } from '../dto/enums';
import { formatDate } from '@angular/common';
import { cmFormatDate } from '../services/util';
// import { formatDate } from '../services/util';

export class BookingDMC {
    constructor(
        // private broadcast: IBroadCastService,
        private domainEvent: IDomainEvent,
    ) {
        this.calculationTarget = CalculationTargetEnum.none;
        this.dto = new BookingDTO();
        this.readOnly = new ReadOnly();
        this.show = new Visible();
        this.subject_DTOChanges = new BehaviorSubject<BookingDTO>(this.dto);
        // this.subscribe_BookingChanges = this.broadcast.observeBookingChanges()
        //     .subscribe(b => this.onChangeOtherBooking(b));
        this.subscribe_BookingChanges = this.domainEvent.subject_BookingChange.asObservable()
            .subscribe(b => this.onChangeOtherBooking());
    }
    public dto: BookingDTO;
    public show: Visible;
    public readOnly: ReadOnly;
    public calculationTarget: CalculationTargetEnum;
    private subscribe_BookingChanges: Subscription;
    private fields: string[] = ['date'];
    public subject_DTOChanges: BehaviorSubject<BookingDTO>;

    private fBookingDate: Date = new Date();
    public get bookingDate(): Date {
        /* Can't return a new Date object: property bound to template
        and it would cause an infinite loop */
        const bDate = new Date(this.dto.bookingDate);
        this.fBookingDate.setDate(bDate.getDate());
        this.fBookingDate.setMonth(bDate.getMonth());
        this.fBookingDate.setFullYear(bDate.getFullYear());
        return this.fBookingDate;
    }
    public set bookingDate(date: Date) {
        this.dto.bookingDate = cmFormatDate(date);
    }

    public get hiddenFields(): string[] {
        const rv: string[] = [];
        this.fields.forEach(f => {
            if (!this.show[f]) {
                rv.push(f);
            }
        });
        return rv;
    }
    calculate() {
        switch (this.calculationTarget) {
            case CalculationTargetEnum.amount:
                if (this.dto.quantity && this.dto.price) {
                    this.dto.amount = this.dto.quantity * this.dto.price;
                } else {
                    this.dto.amount = undefined;
                }
                break;
            case CalculationTargetEnum.price:
                if (this.dto.quantity && this.dto.amount) {
                    this.dto.price = this.dto.amount / this.dto.quantity;
                } else {
                    this.dto.price = undefined;
                }
                break;
            case CalculationTargetEnum.quantity:
                if (this.dto.price && this.dto.amount) {
                    this.dto.quantity = this.dto.amount / this.dto.price;
                } else {
                    this.dto.quantity = undefined;
                }
                break;
            case CalculationTargetEnum.none:
                break;
            default:
                throw new Error('calculationTarget must be specified');
        }
    }
    changeCalculationTarget(target: string) {
        const eTarget: CalculationTargetEnum = CalculationTargetEnum[target];
        this.calculationTarget = eTarget;
        switch (this.calculationTarget) {
            case CalculationTargetEnum.price:
                this.readOnly.price = true;
                this.readOnly.amount = false;
                this.readOnly.quantity = false;
        }
    }
    changeValue(amount: number, price: number, quantity: number) {
        // console.log('changeValue',amount,price,quantity);
        this.dto.amount = amount;
        this.dto.price = price;
        this.dto.quantity = quantity;
        /* local impact */
        this.calculate();
        this.subject_DTOChanges.next(this.dto);
        /*remote impact*/
        this.domainEvent.subject_BookingChange.next(true);
    }

    configure() {
        switch (this.dto.bookingType) {
            case BookingTypeEnum.Acquire:
                switch (this.domainEvent.eventType) {
                    case EventTypeEnum.Split:
                    case EventTypeEnum.Delisting:
                        this.show.quantity = true;
                        this.calculationTarget = CalculationTargetEnum.none;
                        break;
                    case EventTypeEnum.Merger:
                    case EventTypeEnum.SpinOff:
                        this.show.symbol = true;
                        this.show.quantity = true;
                        this.show.price = true;
                        this.show.amount = true;
                        this.calculationTarget = CalculationTargetEnum.amount;
                        this.readOnly.amount = true;
                        break;
                    case EventTypeEnum.Purchase:
                        this.show.quantity = true;
                        this.show.price = true;
                        this.show.amount = true;
                        this.calculationTarget = CalculationTargetEnum.amount;
                        this.readOnly.amount = true;
                }
                break;
            case BookingTypeEnum.Yield:
            case BookingTypeEnum.WithheldTax:
            case BookingTypeEnum.ADRFee:
            case BookingTypeEnum.ReduceCost:
            case BookingTypeEnum.PCommission:
            case BookingTypeEnum.SCommission:
            case BookingTypeEnum.CashOutflow:
            case BookingTypeEnum.BankingFee:
                this.show.amount = true;
                this.calculationTarget = CalculationTargetEnum.none;
                break;
            case BookingTypeEnum.CIL:
            case BookingTypeEnum.Sale:
                this.show.quantity = true;
                this.show.price = true;
                this.show.amount = true;
                this.calculationTarget = CalculationTargetEnum.amount;
                this.readOnly.amount = true;
                break;
            case BookingTypeEnum.Reinvest:
                this.show.quantity = true;
                this.show.price = true;
                this.show.amount = true;
                this.calculationTarget = CalculationTargetEnum.quantity;
                this.readOnly.quantity = true;
                // this.readOnly.amount = true;
                break;
        }
        if (this.dto.symbol) {
            this.show.symbol = this.dto.symbol !== this.domainEvent.selectedSymbol;
        }
        if (this.dto.bookingDate) {
            this.show.date = this.bookingDate.toDateString !== this.domainEvent.eventDate.toDateString;
        }

    }
    getProceeds(): number {
        let rv: number;
        switch (this.dto.bookingType) {
            case BookingTypeEnum.Yield:
                rv = this.dto.amount;
                break;
            case BookingTypeEnum.WithheldTax:
            case BookingTypeEnum.ADRFee:
                rv = - this.dto.amount;
                break;
            default:
                rv = 0;
                break;
        }
        return rv;
    }
    // onChange() {
    //     this.broadcast.nextBookingChange(this);
    // }
    onChangeOtherBooking(): void {
        switch (this.dto.bookingType) {
            case BookingTypeEnum.Reinvest:
                this.dto.amount = this.domainEvent.getProceeds();
                if (this.dto.amount === 0) { this.dto.amount = undefined; }
                break;
        }
        this.calculate();
        this.subject_DTOChanges.next(this.dto);
    }
    unsubscribe(): void {
        this.subscribe_BookingChanges.unsubscribe();
    }

}
class Visible {
    date: boolean;
    amount: boolean;
    price: boolean;
    quantity: boolean;
    symbol: boolean;
}
class ReadOnly {
    amount: boolean;
    price: boolean;
    quantity: boolean;
}
enum CalculationTargetEnum {
    amount,
    price,
    quantity,
    none,
}
// interface IBroadCastService {
//     /* To avoid a circular reference */
//     observeBookingChanges(): Observable<BookingDMC>;
//     nextBookingChange(booking: BookingDMC);
// }
interface IDomainEvent {
    /* To avoid a circular reference */
    eventType: EventTypeEnum;
    subject_BookingChange: BehaviorSubject<boolean>;
    selectedSymbol: string;
    eventDate: Date;
    getProceeds(): number;
}
