import { Component, OnInit } from '@angular/core';
import { BackEndService } from '../services/back-end.service';
import { AssetTypeEnum } from '../asset/asset.dmc';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html'
})
export class CashComponent implements OnInit {
  constructor(private services: BackEndService) { }
  ngOnInit() {
    this.services.broadcast.setSelectedAssetType(AssetTypeEnum.Cash);
    // this.services.data.asset.assetType = AssetTypeEnum.Cash;
    // this.services.model.transaction.useCase = UseCaseEnum.New;
  }
}
