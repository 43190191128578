import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs/Subscription';
import { BackEndService } from '../services/back-end.service';
// import { TransactionDTO } from '../dto/transaction-dto';
import { EventTypeEnum } from '../dto/enums';
import { EventSummaryDTO } from '../dto/event-summary.dto';

@Component({
  selector: 'app-asset-event-history',
  templateUrl: './asset-event-history.component.html',
})
export class AssetEventHistoryComponent implements OnInit, OnDestroy {
  constructor(private services: BackEndService) { }

  subscribe_AssetFocused: Subscription;
  subscription_AssetUpdated: Subscription;
  datasource = new MatTableDataSource<EventSummaryDTO>([]);
  displayedColumns: string[] = ['eventDate', 'eventType', 'quantity', 'price', 'cost', 'proceeds'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  onSelect(item: EventSummaryDTO) {
    // this.services.broadcast.setSelectedEvent(item.eventId);
    this.services.broadcast.eventSelected.next(item.eventId);
  }
  getDisplayValue(item: number): string {
    return EventTypeEnum[item];
  }
  ngOnInit() {
    this.datasource.paginator = this.paginator;
    this.subscribe_AssetFocused = this.services.broadcast
      .observe_AssetFocused
      .subscribe(assetId => this.onAssetUpdated());
    this.subscription_AssetUpdated = this.services.broadcast
      .observe_AssetUpdated
      .subscribe(
        dtos => this.onAssetUpdated()
      );
  }
  onAssetUpdated() {
    this.datasource.data = this.services.model.application.focusedAsset.events;
  }
  // onEventHistoryLoaded(dtos: HistoryItemDTO[]) {
  //   this.datasource.data = dtos;
  // }
  ngOnDestroy() {
    // this.sbsPositionHistory.unsubscribe();
    // this.subscription_EventHistory.unsubscribe();
    this.subscription_AssetUpdated.unsubscribe();
    this.subscribe_AssetFocused.unsubscribe();
  }
}

