import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { BackEndService } from '../services/back-end.service';
import { CashPositionInfoDTO } from '../dto/cash-position-info-dto';

@Component({
  selector: 'app-cash-position-info',
  templateUrl: './cash-position-info.component.html'
})
export class CashPositionInfoComponent implements OnInit, OnDestroy {
  constructor(private services: BackEndService) { }

  cashPositionInfo: CashPositionInfoDTO;
  cashPositionInfoSubscription: Subscription;
  ngOnInit(): void {
    this.cashPositionInfoSubscription = this.services.api
      .obsCashPositionInfo
      .subscribe(cashPositionInfo => {
        this.cashPositionInfo = cashPositionInfo;
      });
      // Get current value now
      this.services.api.getCashPositionInfoDTO();
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.cashPositionInfoSubscription.unsubscribe();
}
}
