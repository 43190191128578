import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { BackEndService } from './services/back-end.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private auth: AuthService, private backend: BackEndService) {

  }
  title = 'app';
  ngOnInit(): void {


  }
}
