<div *ngIf="event">
<div>
    <mat-form-field>
        <mat-select placeholder="Type" (selectionChange)="onChangeEventType()"
            [(ngModel)]="event.eventType">
            <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                {{eventTypeEnumeration[eventType]}}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let bookingType of event.getBookingTypes()"
            (click)="onClickMenu(bookingType)"> {{bookingTypeEnumeration[bookingType]}}</button>
    </mat-menu>
    <button mat-icon-button color="accent" (click)="onClickSaveEvent()">
        <mat-icon>check</mat-icon>
    </button>
    <button mat-icon-button (click)="onClickCancelEvent()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div>
    <mat-form-field>
        <input matInput [matDatepicker]="myDatepicker" placeholder="Date" [(ngModel)]="event.eventDate">
        <mat-datepicker #myDatepicker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
    </mat-form-field>
</div>
<div>
    <app-booking *ngFor="let booking of event.bookings" [booking]="booking"></app-booking>
</div>
<mat-form-field>
    <input matInput type="text" placeholder="Comment" [(ngModel)]="event.dto.comment">
</mat-form-field>
</div>