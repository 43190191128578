import { Component, OnInit, OnDestroy } from '@angular/core';
import { BackEndService } from '../services/back-end.service';
import { Subscription } from 'rxjs/Subscription';
import { AssetDMC, AssetTypeEnum } from './asset.dmc';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html'
})
export class AssetComponent implements OnInit, OnDestroy {
  constructor(private services: BackEndService) { }

  subscribe_AssetFocused: Subscription;
  subscribe_AssetUpdated: Subscription;
  subscribe_EventSelected: Subscription;
  expandEventPanel: boolean;
  editingName: boolean;
  showPositionPanel: boolean;

  assetId: number;
  name: string;
  symbol: string;
  cusip: string;

  ngOnInit() {
    this.subscribe_AssetFocused = this.services.broadcast
      .observe_AssetFocused
      .subscribe(assetId => this.onAssetUpdated(assetId));
    this.subscribe_AssetUpdated = this.services.broadcast
      .observe_AssetUpdated
      .subscribe(assetId => this.onAssetUpdated(assetId));
    this.subscribe_EventSelected = this.services.broadcast
      .eventSelected
      .subscribe('AssetComponent', eventId => this.onEventSelected(eventId));
    this.services.broadcast.setSelectedAssetType(AssetTypeEnum.Market);
  }
  onAssetUpdated(assetId: number): void {
    const assetDMC: AssetDMC = this.services.model.application.focusedAsset;
    if (assetId === assetDMC.assetId) {
      this.assetId = assetDMC.assetId;
      this.name = assetDMC.symbolInfo.name;
      this.symbol = assetDMC.symbolInfo.symbol;
      this.cusip = assetDMC.symbolInfo.cusip;
      this.showPositionPanel = Boolean(this.assetId);
    }
  }
  onEventSelected(eventId: number): void {
    if (eventId) {
      this.expandEventPanel = true;
    }
  }
  onClickEdit() {
    this.editingName = true;
  }
  onClickSave() {
    const assetDMC: AssetDMC = this.services.model.application.focusedAsset;
    assetDMC.symbolInfo.name = this.name;
    assetDMC.symbolInfo.cusip = this.cusip;
    assetDMC.postAssetIdentifier();
    this.editingName = false;
  }
  onClickCancel() {
    this.editingName = false;
  }
  nameWidthStyle(): string {
    const length = this.name.length + 10;
    const widthValue = length + 'ch';
    return widthValue;
  }
  ngOnDestroy() {
    this.subscribe_EventSelected.unsubscribe();
    this.subscribe_AssetUpdated.unsubscribe();
    this.subscribe_AssetFocused.unsubscribe();
  }
}
