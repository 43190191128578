<mat-table [dataSource]="datasource">
    <!-- Date Column -->
    <ng-container matColumnDef="eventDate">
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let transaction"><a (click)="onSelect(transaction)">{{transaction.eventDate}}</a> </mat-cell>
    </ng-container>
    <!-- Transaction Type Column -->
    <ng-container matColumnDef="eventType">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let transaction">{{getDisplayValue(transaction.eventType)}}</mat-cell>
    </ng-container>
    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef> Quantity </mat-header-cell>
        <mat-cell *matCellDef="let transaction"> {{transaction.quantity}} </mat-cell>
    </ng-container>
    <!-- Price Column -->
    <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
        <mat-cell *matCellDef="let transaction"> {{transaction.price}} </mat-cell>
    </ng-container>
    <!-- CostBasis Column -->
    <ng-container matColumnDef="cost">
        <mat-header-cell *matHeaderCellDef> Cost </mat-header-cell>
        <mat-cell *matCellDef="let transaction"> {{transaction.cost}} </mat-cell>
    </ng-container>
    <!-- Proceeds Column -->
    <ng-container matColumnDef="proceeds">
        <mat-header-cell *matHeaderCellDef> Proceeds </mat-header-cell>
        <mat-cell *matCellDef="let transaction"> {{transaction.proceeds}} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator [hidePageSize]="true" [pageSize]="5"></mat-paginator>