import { Subscription, Observable, BehaviorSubject } from 'rxjs';

export class CMNotifier<T> {
    constructor(public eventSignature: string, public verbose?: boolean) {
        this.observer = new CMObserver<T>();
    }
    private observer: CMObserver<T>;
    public next(callerSignature: string, value: T): void {
        if (this.verbose) {
            this.logEvent(callerSignature, value, 'next');
        }
        this.observer.next(value);
    }
    public subscribe(callerSignature: string, callback: (value: T) => void): Subscription {
        return this.observer.observable.subscribe(value => {
            if (this.verbose && value) {
                this.logEvent(callerSignature, value, 'observe');
            }
            // callback.call(this, value);
            callback(value);
        });
    }
    private logEvent(callerSignature: string, value: T, eventInfo: string) {
        console.log(`${this.eventSignature}: ${eventInfo}`);
        if (callerSignature) {
            console.log(`@${callerSignature}`);
        }
        console.log(value);
    }
    public asObservable(): Observable<T> {
        return this.observer.observable;
    }
    public getValue(): T {
        return this.observer.getValue();
    }
    public dispose(): void {
        return this.observer.dispose();
    }
}
class CMObserver<T> {
    public subject = new BehaviorSubject<T>(undefined);
    public get observable(): Observable<T> {
        return this.subject.asObservable();
    }
    public next(value: T): void {
        this.subject.next(value);
    }
    public getValue(): T {
        return this.subject.getValue();
    }
    public dispose(): void {
        this.subject.complete();
    }
}
export class CMSubscriptions {
    private collection: CMSubscription[] = [];
    private verbose: boolean;
    private context: string;
    public add(name: string): CMSubscription {
        if (this.verbose) { console.log('add', name, this.context); }
        if (this.get(name)) {
            throw new Error(`${name} already exists`);
        } else {
            const cm$ = new CMSubscription(name);
            if (this.verbose) { cm$.setVerbose(this.context); }
            this.collection.push(cm$);
            return cm$;
        }
    }
    /**
     * Logs actions to console.
     */
    public setVerbose(context: string): CMSubscriptions {
        this.context = `@${context}`;
        this.verbose = true;
        this.collection.forEach(s => s.setVerbose(this.context));
        return this;
    }
    public get(name: string): CMSubscription {
        return this.collection.find(s => s.name === name);
    }
    public isConsumed(name: string): boolean {
        return this.get(name).isConsumed;
    }
    public remove(name: string): void {
        if (this.get(name)) {
            this.consume(name);
        }
    }
    public consume(name: string): void {
        if (this.verbose) { console.log('consume', name, this.context); }
        const cm$: CMSubscription = this.get(name);
        cm$.consume();
        const idx: number = this.collection.indexOf(cm$);
        this.collection.splice(idx);
    }
    public unsubscribeAll(): void {
        this.collection.forEach(cm$ => cm$.dispose());
    }
}
export class CMSubscription {
    constructor(public name: string) { }
    private fSubscription: Subscription;
    public isConsumed = false;
    private verbose: boolean;
    private context: string;
    public set $(subscription: Subscription) {
        if (this.verbose) {
            let verb: string;
            if (this.isConsumed) { verb = 'ignore'; } else { verb = 'wait'; }
            console.log(verb, this.name, this.context);
        }
        this.fSubscription = subscription;
        if (this.isConsumed) {
            this.dispose();
        }
    }
    public consume() {
        if (this.isConsumed) {
            throw new Error(`${this.name} already consumed`);
        }
        this.isConsumed = true;
        this.dispose();
    }
    public dispose() {
        // console.log(`$: ${this.name} dispose`);
        if (this.fSubscription) {
            if (this.verbose) {
                console.log('unsubscribe', this.name, this.context);
            }
            this.fSubscription.unsubscribe();
            this.fSubscription = undefined;
        }
    }
    public setVerbose(context: string): CMSubscription {
        this.context = context;
        this.verbose = true;
        return this;
    }
}
