import { BroadcastService } from '../services/broadcast.service';
import { PortfolioDMC } from '../portfolio/portfolio.dmc';
import { APIService } from '../services/api.service';
import { isNumber } from 'util';
import { AssetDMC, AssetTypeEnum } from '../asset/asset.dmc';
import { EventDMC } from '../event/event.dmc';

export class ApplicationDMC {
    constructor(private broadcast: BroadcastService, private api: APIService) {
        this.portfolio = new PortfolioDMC(
            this.api,
            this.broadcast);
        this.focusedEvent = null;
        this.broadcast.observe_AssetTypeSelected
            .subscribe(assetType => this.onAssetTypeSelected(assetType));
        this.broadcast.observe_AssetSelected
            .subscribe(assetId => this.onAssetSelected(assetId));
        this.broadcast.observe_AssetSearching
            .subscribe(value => this.onAssetSearching(value));
        this.broadcast.observe_AssetIdentifierPosted
            .subscribe(value => this.onAssetIdentifierPosted(value));
        this.broadcast.observe_AssetsLoaded
            .subscribe(value => this.onAssetsLoaded());

        this.api.getAssets();
    }
    public portfolio: PortfolioDMC;
    private _focusedAsset: AssetDMC;
    private _assetType: AssetTypeEnum = AssetTypeEnum.Market;
    public get focusedAsset(): AssetDMC {
        return this._focusedAsset;
    }
    public set focusedAsset(dmc: AssetDMC) {
        if (!dmc) {
            dmc = new AssetDMC(this.api, this.broadcast);
            dmc.assetType = AssetTypeEnum.Market;
        }
        this._focusedAsset = dmc;
        this.broadcast.setFocusedAsset(this._focusedAsset.assetId);
        // this.broadcast.setUpdatedAssetType(this._focusedAsset.assetType);
    }
    private _focusedEvent: EventDMC;
    public get focusedEvent(): EventDMC {
        return this._focusedEvent;
    }
    public set focusedEvent(dmc: EventDMC) {
        if (dmc === null) {
            dmc = new EventDMC(this.broadcast, this.api);
        }
        this._focusedEvent = dmc;
        this.broadcast.setFocusedEvent(this._focusedEvent.eventId);
    }

    private onAssetIdentifierPosted(assetID: number) {
        if (assetID) {
            this.api.getAsset(assetID);
        }
    }
    private onAssetSearching(value: string): void {
        this.focusedAsset = null;
        if (value) {
            if (isNumber(value)) {
                this.focusedAsset = this.portfolio.getAsset(Number(value));
            } else {
                this.focusedAsset.symbolInfo.symbol = value.toUpperCase();
                this.focusedAsset.symbolInfo.name = '???';
                this.broadcast.setUpdatedAsset(this._focusedAsset.assetId);
            }
        }
    }
    private onAssetSelected(assetId: number): void {
        if (assetId) {
            this.focusedAsset = this.portfolio.getAsset(assetId);
        }
    }
    private onAssetsLoaded() {
        this.onAssetTypeSelected(this._assetType);
    }
    private onAssetTypeSelected(assetType: AssetTypeEnum): void {
        this._assetType = assetType;
        if (assetType) {
            switch (assetType) {
                case AssetTypeEnum.Market:
                    break;
                case AssetTypeEnum.Cash:
                    this.broadcast.setSelectedAsset(1071);
                    break;
            }
        }
    }
}
